import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/o-nas',
    name: 'O nas',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/cennik',
    name: 'Cennik',
    component: () => import(/* webpackChunkName: "cennik" */ '../views/Cennik.vue')
  },
  {
    path: '/Kontakt',
    name: 'Kontakt',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/Regulamin',
    name: 'Regulamin',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Rules.vue')
  },
  {
    path: '/Zamow',
    name: 'Zamów',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Order.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
