import Vue from 'vue'
import App from './App.vue'
import router from './router'

import '@/assets/main.scss'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookSquare, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faHouse, faPhone, faFile, faPaperPlane } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faFacebookSquare, faFile, faPaperPlane, faInstagram, faTiktok, faPhone, faEnvelope, faHouse, faPhone)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.prototype.$showCard = {show: false, value: 0}

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
