<template>
  <div class="hero is-fullheight">
    <div class="hero-head absolute">
      <Navbar></Navbar>
    </div>
    <div class="hero-body">
      <img src="/images/logo.png" class="logo">
    </div>
    <div class="hero-foot">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue';
import Navbar from '@/components/Navbar.vue';

export default {
  components: {
    Footer, Navbar
  }
}
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/mixins";

.hero {
    background: url("/images/bg.webp") no-repeat center;
    background-size: cover;
}

.logo {
  margin: 0 auto;
  max-height: 250px;

  @include until-widescreen {
      // height: 100px;
      // height: 200px;
      // height: 20vw;
      height: clamp(100px, 25vw, 200px);
  }
}
</style>
