<template>
  <div :class="['loadingScreen', {'visible': show}, {'hidden': !show}]">
    <img src="/images/logoSmall.svg" class="logo" />
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean
  }
}
</script>

<style scoped lang="scss">
.loadingScreen {
  min-height: 100vh;
  min-width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  z-index: -100;

  background-color: var(--dark);


  opacity: 0;
  &.hidden {
    opacity: 0;
    z-index: -100;

    transition: z-index .5s step-end, opacity .5s;
  }
  &.visible {
    opacity: 1;
    z-index: 100;
  }
}

.logo {
  height: 90px;
}
</style>
