<template>
  <div id="app">
    <Loader :show="show"></Loader>
    <router-view/>
  </div>
</template>
<script>
import Loader from "./components/Loader";

export default {
  components: {Loader},
  data() {
    return {
      show: true
    }
  }, created() {
    const vue = this;
    setTimeout(() => vue.show = false, 500);
  }, watch:{
    $route (){
      this.show = true
      const vue = this;
      setTimeout(() => vue.show = false, 500);
    }
  }
}
</script>
